import NextLink from 'next/link';
import { UmbracoTypes } from '~/lib/data-contract';
import { UmbracoImage } from '~/shared/components/Image';
import { Link } from '~/shared/components/Link';
import { Text } from '~/shared/components/Text';
import { useModule } from '../../hooks/useModule';
import { ModuleContainer } from '../../shared/ModuleContainer';
import { StyledGrid, StyledImage, StyledItem } from './styled';

type M60USPProps = UmbracoTypes.IM60UspModule;

export const M60USP = ({ items, ...rest }: M60USPProps) => {
    const { index: moduleIndex } = useModule();

    return (
        <ModuleContainer hasGutter {...rest}>
            <StyledGrid count={items.length}>
                {items.map(({ headline, text, image, link }, index) => (
                    <StyledItem key={index} withLink={Boolean(link?.url)}>
                        {link?.url ? (
                            <NextLink href={link.url} passHref legacyBehavior>
                                <Link animation="none">
                                    <USPContent {...{ headline, text, image, moduleIndex }} />
                                </Link>
                            </NextLink>
                        ) : (
                            <USPContent {...{ headline, text, image, moduleIndex }} />
                        )}
                    </StyledItem>
                ))}
            </StyledGrid>
        </ModuleContainer>
    );
};

const USPContent = ({
    image,
    headline,
    text,
    moduleIndex,
}: UmbracoTypes.IUspItem & { moduleIndex: number }) => (
    <>
        {image ? (
            <StyledImage>
                <UmbracoImage
                    src={image.src}
                    alt={image.alt ?? ''}
                    height={60}
                    width={60}
                    priority={moduleIndex < 2}
                />
            </StyledImage>
        ) : null}

        {headline || text ? (
            <div>
                {headline ? <Text variant="paragraph">{headline}</Text> : null}

                {text ? <Text>{text}</Text> : null}
            </div>
        ) : null}
    </>
);
