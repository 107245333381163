import styled from '@emotion/styled';
import { ifProp, switchProp } from 'styled-tools';
import { StyledText } from '~/shared/components/Text';
import { cover } from '~/shared/utils/styled';
import { breakpoints } from '~/theme';

type StyledGridProps = {
    count: number;
};

const calculateGap = (count: number, gap: string) => {
    const gapValue = parseInt(gap.replace(/\D/, ''));
    const gapCount = Math.max(count - 1, 0);
    const gapSpace = gapValue * gapCount;
    return gapSpace / count;
};

const flex = (columns: number, count: number, gap: string) => {
    return `0 0 calc(${100 / columns}% - ${calculateGap(count, gap)}px)`;
};

export const StyledGrid = styled.ul<StyledGridProps>(
    ({ theme }) => ({
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: theme.space['6'],
        [breakpoints.md]: {
            gap: theme.space['5'],
        },
    }),
    switchProp(
        'count',
        {
            4: ({ theme, count }) => ({
                [`${StyledItem}`]: {
                    flex: flex(2, count, theme.space[6]),
                    [breakpoints.md]: {
                        flex: flex(4, count, theme.space[5]),
                    },
                },
            }),
        },
        ({ theme, count }) => ({
            [`${StyledItem}`]: {
                flex: flex(2, count, theme.space[6]),
                '@media(min-width: 600px)': {
                    flex: flex(3, count, theme.space[6]),
                },
                [breakpoints.md]: {
                    flex: flex(4, count, theme.space[5]),
                },
            },
        }),
    ),
);

export const StyledItem = styled.li<{ withLink?: boolean }>(
    ({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        alignItems: 'center',
        gap: theme.space['3'],
        position: 'relative',

        [breakpoints.md]: {
            textAlign: 'left',
            gap: theme.space['4'],
            flexDirection: 'row',
            justifyContent: 'start',
        },

        img: {
            display: 'block',
            maxWidth: '100%',
            height: 'auto',
        },
    }),

    ifProp('withLink', ({ theme }) => ({
        '>a >span': {
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            alignItems: 'center',
            gap: theme.space['3'],
            position: 'relative',

            [breakpoints.md]: {
                textAlign: 'left',
                gap: theme.space['4'],
                flexDirection: 'row',
                justifyContent: 'start',
            },

            img: {
                display: 'block',
                maxWidth: '100%',
                height: 'auto',
            },
        },

        [`a${StyledText}`]: {
            textDecoration: 'none',

            '&:after': {
                content: '""',
                ...cover,
            },

            '&:hover': {
                color: theme.colors.darkBlue,
            },
        },
    })),
);

export const StyledImage = styled.div({
    width: 60,
});
